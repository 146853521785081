.Collapsible {
  border-top: 1px solid #d1d1d1;
}

.Collapsible:last-of-type {
  border-bottom: 1px solid #d1d1d1;
}

.Collapsible__contentInner {
  padding: 0 1.5rem 1.5rem 1.5rem;
}

.Collapsible__contentInner p {
  text-align: left;
  line-height: 1.3;
  margin-bottom: 1.5rem;
}

.Collapsible__contentInner p:last-child {
  margin-bottom: 0;
}

.Collapsible__trigger {
  display: block;
  text-align: left;
  font-weight: 700;
  position: relative;
  padding: 1.5rem;
}


.Collapsible__trigger:after {
  display: inline-block;
  content: '';
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(45deg);

  position: absolute;
  right: 1.5rem;
  top: calc(1.5rem + 10px / 2);
  transition: transform 300ms;
}

.Collapsible__trigger.is-open:after {
  transform: rotate(225deg);
}

.Collapsible__trigger.is-disabled {
  opacity: 0.5;
  background-color: grey;
}

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
}

.CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #CBB700;
  color: black;
}
